html {
  --section-background-color: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.582),
    rgba(11, 18, 80, 0.904)
  );

  --image-gradient: linear-gradient(
    to bottom left,
    rgba(17, 16, 16, 0.678),
    rgba(12, 10, 22, 0.863)
  );

  --imp-text-color: #06173c;
}


button:focus {
  box-shadow: none;
}

#preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  background-color: #0c0513;
  background-image: url(./Assets/pre.svg);
  background-repeat: no-repeat;
  background-position: center;
}

#preloader-none {
  opacity: 0;
}

#no-scroll {
  overflow: hidden;
  height: 100vh;
}


::-webkit-scrollbar {
  width: 7px;
}


::-webkit-scrollbar-track {
  background: #2d1950;
}


::-webkit-scrollbar-thumb {
  background: rgba(30, 18, 116, 0.959);
  border-radius: 12px;
}


::-webkit-scrollbar-thumb:hover {
  background: rgba(30, 18, 116, 0.959);
  border-radius: 12px;
}

.sticky {
  background-color: #1b1a2ea9;
  transition: all 0.3s ease-out 0s ;
  box-shadow: 0px 10px 10px 0px rgba(9, 5, 29, 0.171);
  backdrop-filter: blur(15px);
}

.navbar {
  position: fixed ;
  transition: all 0.3s ease-out 0s ;
  padding: 0.3rem 2rem ;
  font-size: 1.2rem;
}

.navbar-toggler {
  position: relative;
  background-color: transparent ;
  border-color: transparent ;
}

.navbar-toggler span {
  display: block ;
  background-color: #e7eb26;
  height: 4px ;
  width: 27px;
  margin-top: 5px ;
  margin-bottom: 5px ;
  transform: rotate(0deg);
  left: 0 ;
  opacity: 1 ;
}

.navbar-toggler:focus,
.navbar-toggler:active {
  outline: 0 ;
}

.navbar-toggler span:nth-child(1),
.navbar-toggler span:nth-child(3) {
  transition: transform 0.35s ease-in-out ;
  transition: transform 0.35s ease-in-out ;
}

.navbar-toggler:not(.collapsed) span:nth-child(1) {
  position: absolute ;
  left: 12px ;
  top: 10px ;
  transform: rotate(135deg) ;
  opacity: 0.9 ;
  color: white !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(2) {
  height: 12px ;
  visibility: hidden;
  background-color: transparent;
  color: white !important;
}

.navbar-toggler:not(.collapsed) span:nth-child(3) {
  position: absolute ;
  left: 12px ;
  top: 10px ;
  transform: rotate(-135deg);
  opacity: 0.9;
  color: white !important;
}

@media (max-width: 767px) {
  .navbar {
    padding: 1rem 2rem ;
    font-size: 1.4rem ;
    background-color: #181a27;
  }
  .navbar-nav .nav-item a::after {
    display: none ;
  }
}
.navbar-brand {
  color: rgb(250, 250, 250) ;
}

.logo {
  height: 4px;
  width: 3px ;
}

.navbar-nav .nav-link {
  color: white ;
  padding-right: 1rem ;
  padding-left: 1rem ;
}

.nav-link {
  padding: 0.8rem 1rem;
}

@media (max-width: 767px) {
  .nav-link {
    padding: 0.7rem 1rem ;
  }
}

.navbar-nav .nav-item {
  position: relative;
  margin-left: 20px;
}

.navbar-nav .nav-item a {
  font-weight: 400;
  transition: all 0.3s ease-out 0s;
  position: relative;
  z-index: 1;
}

.navbar-nav .nav-item a::after {
  content: "";
  position: relative;
  display: block;
  height: 5px;
  width: 0;
  border-radius: 16px;
  background: #5badf5;
  bottom: 1px;
  left: 0;
  z-index: -1;
  transition: all 0.3s ease-out 0s;
}

.navbar-nav .nav-item a:hover::after {
  width: 100%;
}

.wave {
  animation-name: wave-animation; 
  animation-duration: 2.1s;
  animation-iteration-count: infinite; 
  transform-origin: 70% 70%; 
  display: inline-block;
}

@keyframes wave-animation {
  0% {
    transform: rotate(0deg);
  }
  10% {
    transform: rotate(14deg);
  } 
  20% {
    transform: rotate(-8deg);
  }
  30% {
    transform: rotate(14deg);
  }
  40% {
    transform: rotate(-4deg);
  }
  50% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(0deg);
  } 
  100% {
    transform: rotate(0deg);
  }
}
#tsparticles {
  position: fixed !important;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  width: 100%;
  height: 100%;
}

.home-header {
  padding-top: 80px !important;
}
.home-section {
  position: relative;
  z-index: -1;
  background-image: var(--image-gradient), url(./Assets/bg.jpeg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover; /* Make the image cover the entire element */
  padding-bottom: 30px !important;
  padding-top: 30px !important;
}


.home-content {
  padding: 9rem 0 2rem !important;
  color: whitesmoke !important;
  text-align: left;
}

.heading {
  font-size: 2.4em !important;
  padding-left: 50px !important;
}


.main-name {
  color: rgb(205, 201, 8);
}

.Typewriter__wrapper {
  font-size: 2.2em !important;
  color: #6a8bdf !important;
  font-weight: 600 !important;
}
.Typewriter__cursor {
  font-size: 2.4em !important;
  color: #6a8bdf !important;
}

@media (max-width: 767px) {
  .Typewriter__wrapper {
    font-size: 1.4em !important;
    font-weight: 500 !important;
    position: absolute !important;
  }
  .Typewriter__cursor {
    display: none !important;
  }
}


.home-about-section {
  position: relative;
  padding-bottom: 70px !important;
  padding-top: 70px !important;
}

.home-about-description {
  color: white !important;
  padding-top: 100px !important;
  padding-bottom: 20px !important;
  text-align: center;
}

.home-about-body {
  padding-top: 50px;
  font-size: 1.2em !important;
  text-align: left;
}

.home-about-social {
  text-align: center !important;
  padding-top: 25px;
  color: white !important;
}

.home-about-social-links {
  justify-content: center !important;
  padding-top: 15px !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.home-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 40px !important;
  height: 40px !important;
  text-align: center !important;
  font-size: 1.2em !important;
  line-height: 2em !important;
  background: rgba(255, 255, 255, 0.972) !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
}

.home-social-icons::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #8d0c57;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.home-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #8d0c57;
}

.home-social-icons:hover {
  color: #8d0c57;
  box-shadow: 0 0 5px #8d0c57;
  text-shadow: 0 0 2px #8d0c57;
}

.social-icons {
  display: inline-block ;
  padding-right: 15px;
  padding-left: 15px;
}

.icon-colour {
  color: #e6bde7 ;
}

.footer {
  background-color: rgb(1, 0, 3);
  bottom: 0 !important;
  padding-top: 10px !important;
  padding-bottom: 8px !important ;
}


.footer-icons {
  margin-top: 0.5em !important;
  margin-bottom: 0.5em !important;
  padding: 0 !important;
}



.btn-primary {
  color: #fff !important;
  background-color: #623686 !important;
  border-color: #623686 !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: #6d20c5d7 !important;
  border-color: #6d20c5d7 !important;
}
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}


.about-section {
  position: relative !important;
  padding-top: 150px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
}

.tech-icons {
  font-size: 4.5em !important;
  margin: 15px !important;
  padding: 10px !important;
  opacity: 0.93 !important;
  border: 1.7px solid rgba(200, 137, 230, 0.637) !important;
  vertical-align: middle !important;
  text-align: center !important;
  border-radius: 5px !important;
  display: table !important;
  box-shadow: 4px 5px 4px 3px rgba(89, 4, 168, 0.137) !important;
  overflow: hidden !important;
  transition: all 0.4s ease 0s !important;
}

@media (max-width: 767px) {
  .tech-icons {
    margin: 10px !important;
  }
}

.tech-icons:hover {
  transform: scale(1.05) !important;
  overflow: hidden !important;
  border: 2.2px solid rgba(197, 115, 230, 0.883) !important;
}
.tech-icon-images {
  padding: 20px !important;
  line-height: 1.6 !important;
}

.quote-card-view {
  border: none !important;
  color: white !important;
  background-color: transparent !important;
}

.about-activity {
  list-style: none !important;
  text-align: left !important;
  padding-left: 1px !important;
}

@media (max-width: 767px) {
  .about-img {
    padding-top: 0 !important;
  }
}


.resume-section {
  position: relative !important;
  padding-top: 110px !important;
  padding-bottom: 30px !important;
  background-image: var(--section-background-color) !important;
  color: white !important;
}

.resume {
  padding-top: 50px;
  padding-bottom: 50px;
  justify-content: center;
}

.resume-left {
  padding-right: 80px !important;
}

.resume-right {
  padding-left: 80px !important;
}

@media (max-width: 767px) {
  .resume-left {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }

  .resume-right {
    padding-right: 15px !important;
    padding-left: 15px !important;
  }
}
.resume .resume-title {
  font-size: 2em;
  font-weight: 700;
  padding-top: 30px;
  padding-bottom: 30px;
}

.resume .resume-item {
  padding: 0 0 10px 25px;
  margin-top: -2px;
  border-left: 2px solid #390b4e;
  position: relative;
}

.resume .resume-item .resume-title {
  line-height: 18px;
  font-size: 0.9em;
  background: #dfdee15d;
  padding: 8px 15px;
  display: inline-block;
  font-weight: 600;
  margin-bottom: 10px;
}

.resume .resume-item ul {
  padding-left: 20px;
  text-align: justify;
}

.resume .resume-item ul li {
  padding-bottom: 10px;
  list-style: none;
}

.resume .resume-item:last-child {
  padding-bottom: 0;
}

.resume .resume-item::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: #fff;
  border: 2px solid #8a49a8;
}

.like-item {
  padding-top: 10px !important;
  font-size: 1.1em !important;
  font-family: sans-serif !important;
}

.like-btn {
  background-color: #934cce5e !important;
  border-color: #934cce5e !important;
  padding: 0.25rem 0.98rem !important;
  border-radius: 5px !important;
  line-height: 1.4 !important;
  transition: 0.3s ease !important;
}

.like-btn:hover {
  transform: translateY(-2px) !important;
  background-color: #a24dd386 !important;
  border-color: #a24dd386 !important;
}

.animate-like {
  animation-name: likeAnimation;
  animation-fill-mode: forwards;
  animation-duration: 0.85s;
}
@keyframes likeAnimation {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.fork-btn {
  font-size: 1.1em ;
  padding-top: 10px ;
}

.fork-btn-inner {
  line-height: 1.4em ;
  background-color: #934cce5e ;
  padding: 0.25rem 1.1rem ;
  vertical-align: middle ;
  text-align: center;
}

.fork-btn-inner:hover {
  transform: translateY(-2px);
  background-color: #a24dd386 ;
  border-color: #a24dd386 ;
}
.fork-btn-inner::after {
  display: none;
}
